<template>
  <b-row>
    <b-col>
      <b-card title="Bookings">
        <ListCalendarSwitch active="list" />
        <blueprint-data
          api-route-path="bookings" :params="params" :fields="fields" no-new no-actions :where="where"
          :q="q"
        >
          <template v-slot:search>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Search">
              <b-form-input id="name" v-model="q" type="text" placeholder="Search by booking no, firstname, lastname" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Properties">
              <blueprint-search-input
                id="properties"
                v-model="properties"
                :multiple="true"
                placeholder="Type to search Properties"
                label="name"
                track-by="id"
                api-route-path="inventory/properties"
                @input="buildWhere"
              />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Units">
              <blueprint-search-input
                id="units"
                v-model="units"
                :multiple="true"
                placeholder="Type to search Units"
                label="name"
                track-by="id"
                :where="unitsWhere"
                api-route-path="inventory/units"
                @input="buildWhere"
              />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Companies">
              <blueprint-search-input
                id="companies"
                v-model="companies"
                :multiple="true"
                placeholder="Type to search Companies"
                label="companies"
                track-by="id"
                api-route-path="companies"
                @input="buildWhere"
              />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Status" label-for="status">
              <b-form-select id="status" v-model="status" @input="buildWhere">
                <option :value="null">
                  -
                </option>>
                <option value="NEW">
                  New
                </option>>
                <option value="CONFIRMED">
                  Confirmed
                </option>>
                <option value="ACTIVATED">
                  Active
                </option>>
                <option value="CANCELED">
                  Canceled
                </option>>
                <option value="CLOSED">
                  Closed
                </option>
              </b-form-select>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Minimum booking start">
              <datepicker v-model="start" reset-button allow-null @input="buildWhere" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Maximum booking end">
              <datepicker v-model="end" :min="start" reset-button allow-null @input="buildWhere" />
            </b-form-group>
          </template>
          <template v-slot:number="{ item }">
            <router-link :to="{ name: 'bookings.booking.edit', params: { id: item.id } }">
              {{ item.number }}
            </router-link>
          </template>
          <template v-slot:firstname="{ item }">
            {{ item.firstname }}
          </template>
          <template v-slot:lastname="{ item }">
            {{ item.lastname }}
          </template>
          <template v-slot:company="{ item }">
            <span v-if="item.company">{{ item.company.name }}</span>
          </template>
          <template v-slot:range="{ item }">
            {{ item.range.start | moment('LL') }} - {{ item.range.end | moment('LL') }}
          </template>
          <template v-slot:unitId="{ item }">
            {{ item.unit.name }} / {{ item.unit.property.name }}
          </template>
          <template v-slot:status="{ item }">
            <b-badge v-if="item.status==='NEW'" variant="info">
              New
            </b-badge>
            <b-badge v-if="item.status==='CONFIRMED'" variant="warning">
              Confirmed
            </b-badge>
            <b-badge v-if="item.status==='CANCELED'" variant="danger">
              Canceled
            </b-badge>
            <b-badge v-if="item.status==='CLOSED'" variant="dark">
              Closed
            </b-badge>
            <b-badge v-if="item.status==='ACTIVATED'" variant="success">
              Activated
            </b-badge>
          </template>
          <template v-slot:taxId="{ item }">
            {{ item.tax.name }} ({{ item.tax.percentage }}%)
          </template>
          <template v-slot:price_tax="{ item }">
            {{ parseFloat(parseFloat(item.price) + parseFloat(item.price) * parseFloat(item.tax.percentage)/100).toFixed(2) }}/{{ item.priceMethod }}
          </template>
          <template v-slot:price="{ item }">
            {{ parseFloat(item.price).toFixed(2) }}/{{ item.priceMethod }}
          </template>
        </blueprint-data>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import utils from '../../../libs/utils.vue';
import ListCalendarSwitch from './components/list-clendar-switcher.vue';
import Datepicker from '../../../components/date-picker';

export default {
  components: {
    ListCalendarSwitch,
    Datepicker
  },
  data () {
    return {
      errors: {},
      properties: [],
      units: [],
      params: {},
      status: null,
      start: null,
      end: null,
      where: {},
      unitsWhere: {},
      companies: null,
      q: null,
      fields: [
        {
          key: 'number',
          label: 'No.',
          sortable: true,
          slot: true,
        },
        {
          key: 'firstname',
          label: 'Firstname',
          sortable: true,
          slot: true,
        },
        {
          key: 'lastname',
          label: 'Lastname',
          sortable: true,
          slot: true,
        },
        {
          key: 'company',
          label: 'Company',
          sortable: true,
          slot: true,
        },
        {
          key: 'unitId',
          label: 'Unit / Property',
          sortable: true,
          slot: true,
        },
        {
          key: 'range',
          label: 'Start - End',
          sortable: true,
          slot: true,
        },
        {
          key: 'price',
          label: 'Price',
          sortable: true,
          slot: true,
        },
        /*
        {
          key: 'taxId',
          label: 'Tax',
          sortable: true,
          slot: true,
        },
        */
        {
          key: 'price_tax',
          label: 'Price+Tax',
          sortable: false,
          slot: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
          slot: true,
        },
      ],
    };
  },
  watch: {
    properties () {
      this.units= [];
    }
  },
  methods: {
    state (item) {
      return (this.errors[item] ? false : null);
    },
    buildWhere () {
      this.unitsWhere = {
        ...utils.parseWhereItem('propertyId', this.properties),
      };
      const params = {};
      if (this.start){
        params.start = this.start;
      }
      if (this.end){
        params.end = this.end;
      }
      this.params = params;
      this.where = {
        ...utils.parseWhereItem('status', this.status),
        ...utils.parseWhereItem('unitId', this.units),
        ...utils.parseWhereItem('unit.propertyId', this.properties),
        ...utils.parseWhereItem('companyId', this.companies),
      };
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },

  },
};
</script>
